<template>
  <v-row dense>
    <v-col
      v-for="staffMember in staffMembers"
      :key="staffMember.id"
      cols="12"
      lg="6"
    >
      <v-card
        class="pa-1"
        border
        flat
        tile
      >
        <v-card-text>
          <template v-if="staffMember.attachment_url">
            <v-row class="mb-2">
              <v-col>
                <v-img
                  :alt="`${$t('Picture for')} ${staffMember.name}`"
                  :src="staffMember.attachment_url"
                  height="160"
                />
              </v-col>
            </v-row>
          </template>
          <v-row dense>
            <v-col>
              <div class="fw-600 fs-16 fw-500">
                {{ staffMember.name }}
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-3" />
          <v-row>
            <v-col>
              <div class="fs-16 fw-400">
                <span
                  v-if="$store.state.config.enable_automatic_translations"
                  :key="locale"
                  v-translate="staffMember.bio"
                />

                <span v-else>{{ $t(staffMember.bio) }}</span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import publicApi from '@/shared/services/api/public-api';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  provider: {
    type: Object,
    default: null,
  },
});

const { locale } = useI18n();
const staffMembers = ref([]);

onMounted(() => {
  load();
});

function load() {
  publicApi.provider.staff_member.index(props.provider.id, (resp) => {
    staffMembers.value = resp.data;
  });
}
</script>
