import Mustache from 'mustache';
import { useI18n } from 'vue-i18n';

export default function useRenderLiquid({ liquidRenderingEnabled = false, modelValue = {} } = {}) {
  const { t } = useI18n();

  function renderText(text) {
    if (!text) return '';

    // assumes that translation will avoid disrupting {{}} syntax
    const sanitizedText = replaceLiquidSyntax(text);
    const translatedValue = t(sanitizedText);

    return isLiquidValue(translatedValue) ? renderLiquidValue(translatedValue) : translatedValue;
  }

  function isLiquidValue(value) {
    if (!value) return false;

    return value.includes('-%');
  }

  function renderLiquidValue(value) {
    if (!liquidRenderingEnabled) return value;

    return Mustache.render(value, modelValue, {}, ['-%', '%-']).replace('&#39;', "'");
  }

  function replaceLiquidSyntax(text) {
    if (!liquidRenderingEnabled) return text;

    return text.replace(/{{/g, '-%').replace(/}}/g, '%-');
  }

  return {
    renderText,
  };
}
