import { getCurrentInstance } from 'vue';
import useEventBus from '@/shared/composables/useEventBus';

/**
 * Works with form input fields to leverage our labeled field definitions.
 *
 * Append emits to your component emit definition
 * Append props to your component prop definition
 * (example in LabeledSelect.vue)
 *
 * @param {*} boldRef
 * @param {*} globalRef
 * @param {*} lockedRef
 * @param {*} outlinedRef
 * @param {*} emit
 * @param {*} attrs
 * @returns {
 *  appendIcon: string
 *  inputClass: string
 *  labelClass: string
 *  handleBlur: function
 *  handleUpdateModelValue: function
 * }
 */
export default function useLabeledField(boldRef, globalRef, lockedRef, outlinedRef) {
  const eventBus = useEventBus();
  const { attrs, emit } = getCurrentInstance();

  const appendIcon = computed(() => {
    if (attrs['append-icon']) return attrs['append-icon'];
    return lockedRef.value ? 'lock' : '';
  });

  const inputClass = computed(() => (outlinedRef.value ? 'bg-white' : ''));

  const labelClass = computed(() => {
    if (boldRef.value) return 'fw-600 fs-18';
    return 'labeled-input';
  });

  function handleBlur(event) {
    emit('blur', event);
    if (globalRef.value) eventBus.blur(event);
  }

  function handleUpdateModelValue(event) {
    emit('update:modelValue', event);
    emit('change', event);
    emit('input', event);
  }

  return {
    appendIcon,
    inputClass,
    labelClass,
    handleBlur,
    handleUpdateModelValue,
  };
}

useLabeledField.props = {
  autofocus: {
    type: Boolean,
    default: undefined,
  },
  bold: {
    type: Boolean,
    default: undefined,
  },
  cols: {
    type: String,
    default: '12',
  },
  dense: {
    type: Boolean,
    default: undefined,
  },
  description: {
    type: String,
    default: null,
  },
  global: {
    type: Boolean,
    default: undefined,
  },
  lg: {
    type: String,
    default: null,
  },
  md: {
    type: String,
    default: null,
  },
  outlined: {
    type: Boolean,
    default: undefined,
  },
  prefix: {
    type: String,
    default: undefined,
  },
  sm: {
    type: String,
    default: null,
  },
  veryDense: {
    type: Boolean,
    default: undefined,
  },
};
useLabeledField.emits = ['blur', 'change', 'input', 'update:modelValue'];

// Must match order of params accepted by composable
useLabeledField.paramKeys = ['bold', 'global', 'locked', 'outlined'];
