<template>
  <span v-text="fullText" />
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();

const haitianCreoleFormatting = {
  long: [
    'janvye',
    'fevriye',
    'mas',
    'avil',
    'me',
    'jen',
    'jiyè',
    'out',
    'septanm',
    'oktòb',
    'novamn',
    'desamn',
  ],
};
const props = defineProps({
  date: {
    type: String,
    default: null,
  },
  hideYear: Boolean,
  nulltext: {
    type: String,
    default: '',
  },
  prefix: {
    type: String,
    default: '',
  },
  suffix: {
    type: String,
    default: '',
  },
  shortMonth: {
    boolean: false,
    type: Boolean,
  },
});

const fullText = computed(() => {
  if (localeDateString.value) {
    const str = [];
    if (props.prefix) {
      str.push(t(props.prefix));
    }

    str.push(localeDateString.value);

    if (props.suffix) {
      str.push(t(props.suffix));
    }

    return str.join(' ');
  }
  return t(props.nulltext);
});

const localeDateString = computed(() => {
  if (!props.date) {
    return '';
  }

  const date = new Date(props.date);
  const day = date.getUTCDate();

  if (locale.value === 'ht') {
    const month = haitianCreoleFormatting.long[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    return `${day} ${month} ${year}`;
  } else {
    const options = {
      month: props.shortMonth ? 'short' : 'long',
      day: 'numeric',
      timeZone: 'UTC',
    };
    if (!props.hideYear) {
      options.year = 'numeric';
    }
    return date.toLocaleDateString(locale.value, options);
  }
});
</script>
