<template>
  <v-card
    data-cy="row"
    flat
    tile
  >
    <v-card-text>
      <v-row
        class="d-flex align-center"
        data-testid="table-row-row"
      >
        <slot />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup></script>
