<template>
  <div>
    <p v-if="destination">Redirecting to {{ destination.redirect_url }}...</p>
    <p v-else>Redirecting...</p>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const route = useRoute();
const router = useRouter();

const destination = ref(null);

onMounted(() => {
  const { path } = route;
  destination.value = store.state.config.site_redirects.find(
    (siteRedirect) => siteRedirect.path === path,
  );

  if (destination.value) {
    window.location.href = destination.value.redirect_url;
  } else {
    router.replace('/dashboard');
  }
});
</script>
