<template>
  <v-card
    v-show="images && images.length > 0"
    class="pa-0"
    border
    flat
    tile
  >
    <v-carousel
      v-if="images"
      v-model="image_index"
      :show-arrows="images.length > 1"
      next-icon="chevron_right"
      prev-icon="chevron_left"
      hide-delimiters
    >
      <v-carousel-item
        v-for="(image, i) in images"
        :key="i"
      >
        <div class="align-center d-flex fill-height justify-center">
          <v-img
            :src="image.url"
            data-testId="provider-image"
            max-height="500"
            eager
          />
        </div>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import Api from '@/shared/services/bright_finder';

const props = defineProps({
  elevation: {
    type: Number,
    default: 0,
  },
  provider_id: {
    type: String,
    default: null,
  },
});

const route = useRoute();

const images = ref([]);
const image_index = ref(0);

function load() {
  Api.public_api.provider.asset.index(
    props.provider_id || route.params.providerId,
    { category: 'image' },
    (resp) => {
      images.value = resp.data;
    },
  );
}

watch(images, () => {
  image_index.value = 0;
});

watch(
  () => route.params.providerId,
  (newVal) => {
    if (newVal) load();
  },
  { immediate: true },
);

watch(
  () => props.provider_id,
  (newVal) => {
    if (newVal) load();
  },
  { immediate: true },
);

onMounted(() => {
  load();
});
</script>
