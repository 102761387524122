<template>
  <ResourceDialog
    @close="close"
    @save="createPhone"
    ref="phoneDialog"
    :cancel-button-text="cancelButtonText"
    :max-width="600"
    :processing="processing"
    :save-button-text="saveButtonText"
    :title="title"
  >
    <template #form>
      <v-row dense>
        <v-col>
          <p class="fs-16 mb-0">
            {{
              $t(
                'May we send you text messages? If yes, please enter your phone number. You will receive a text to verify this number.',
              )
            }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <LabeledTextfield
          v-model="phone"
          mask="(###) ###-####"
          message="Cell phone number"
        />
        <v-col>
          <v-checkbox
            v-model="textOptedIn"
            :label="
              $t(
                'I consent to receive communication by text message to the cellphone number provided above.',
              )
            "
            class="mt-0 label-opacity-override"
            hide-details
          />
        </v-col>
      </v-row>

      <template v-if="!disablePhoneSkip">
        <v-row>
          <v-col class="d-flex justify-center">
            <v-btn
              @click="neverShowPhonePrompt()"
              color="primary"
              size="small"
              variant="outlined"
            >
              <span>
                {{ $t('Skip and never ask me again') }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </template>
  </ResourceDialog>

  <ResourceDialog
    ref="phoneCodeDialog"
    :max-width="600"
    :processing="processing"
    cancel-button-text="Back"
    save-button-text="Submit"
    title="Confirm your phone number"
  >
    <template #form>
      <v-row>
        <v-col cols="12">
          <p>
            {{ $t('Enter the confirmation code sent to your phone.') }}
          </p>
        </v-col>
        <v-col cols="12">
          <v-otp-input
            v-model="code"
            @finish="checkCode"
            :disabled="processing"
            class="mb-4"
            length="6"
          />
        </v-col>
      </v-row>
    </template>
  </ResourceDialog>
</template>

<script>
import API from '@/shared/mixins/api';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

// File inconsequentially divered from the src original on 2024-05-08.
export default {
  compatConfig: { MODE: 3 },

  components: {
    LabeledTextfield,
    ResourceDialog,
  },

  mixins: [API],

  props: {
    cancelButtonText: {
      type: String,
      default: null,
    },
    saveButtonText: {
      type: String,
      default: null,
    },
    disablePhoneSkip: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: 'Add your phone number',
    },
  },

  emits: ['cancel', 'change', 'close'],

  data() {
    return {
      phone: null,
      confirmed: false,
      code: null,
      processing: false,
      textOptedIn: false,
    };
  },

  methods: {
    close() {
      if (this.confirmed) {
        return this.$emit('close');
      }
      return this.$emit('cancel');
    },

    async createPhone() {
      if (!(this.phone && this.textOptedIn))
        return this.$eventBus.$emit(
          'chime',
          'You must consent to receive text messages at this number to proceed',
        );

      this.processing = true;
      const resp = await this.api.member.phone
        .create({ number: this.phone })
        .catch((error) => this.$eventBus.$emit('error', error));
      this.processing = false;
      if (resp.status !== 201) return false;

      this.$refs.phoneCodeDialog.open({});
      return true;
    },

    async checkCode() {
      this.processing = true;
      const resp = await this.api.member.phone
        .update({ code: this.code })
        .catch((error) => this.$eventBus.$emit('error', error));
      this.processing = false;
      if (resp.status !== 200) return;
      this.confirmed = true;
      this.$eventBus.$emit('chime', 'Saved');
      this.$refs.phoneDialog.close();
      this.$refs.phoneCodeDialog.close();
      this.$emit('change');
    },

    async neverShowPhonePrompt() {
      this.processing = true;
      const resp = await this.api.member.phone
        .skipPhoneDialog()
        .catch((error) => this.$eventBus.$emit('error', error));
      this.processing = false;
      if (resp.status !== 200) return;
      this.confirmed = true;
      this.$eventBus.$emit('chime', 'Saved');
      this.$refs.phoneDialog.close();
      this.$refs.phoneCodeDialog.close();
      this.$emit('change');
    },

    open() {
      this.phone = null;
      this.textOptedIn = null;
      this.code = null;
      return this.$refs.phoneDialog.open();
    },
  },
};
</script>

<style scoped>
.label-opacity-override:deep(.v-label) {
  opacity: 1;
}
</style>
