<template>
  <v-col
    v-if="propertiesExist"
    :cols="computedCols"
    :data-cols="cols"
    :data-name="attributeName"
    :data-role="attributeProperties?.role"
    :lg="lg"
    :md="md"
    data-cy="defined-property-element"
    data-testid="defined-property-element"
  >
    <slot
      :custom-attribute="maybeCustomAttribute"
      :properties="attributeProperties"
    />
  </v-col>
</template>

<script setup>
import _ from 'lodash';
import { useDisplay } from 'vuetify';

const props = defineProps({
  attributeName: {
    type: String,
    default: null,
  },
  cols: {
    type: String,
    default: undefined,
  },
  customAttribute: {
    type: String,
    default: null,
  },
  definition: {
    type: Object,
    default: () => ({}),
  },
  lg: {
    type: String,
    default: undefined,
  },
  md: {
    type: String,
    default: undefined,
  },
  properties: {
    type: Object,
    default: null,
  },
});

const display = useDisplay();

const computedCols = computed(() => {
  if (props.cols) return props.cols;

  if (display.smAndDown.value) return '12';

  if (attributeProperties.value.role === 'text-long') return '12';

  if (attributeProperties.value.format === 'date') return '12';

  return null;
});

const maybeCustomAttribute = computed(() => {
  if (props.customAttribute) return props.customAttribute;

  if (props.attributeName?.includes('custom')) return props.attributeName.split('.')[1];

  return null;
});

const attributeProperties = computed(() => {
  if (props.properties) return props.properties;

  const isCustomAttribute = maybeCustomAttribute;

  if (isCustomAttribute.value)
    return props.definition.properties.custom.properties[isCustomAttribute.value] || {};

  return props.definition.properties[props.attributeName] || {};
});

const propertiesExist = computed(() => !_.isEmpty(attributeProperties.value));
</script>
