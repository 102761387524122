<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <v-row
      v-if="includedCheck"
      dense
    >
      <v-col>
        <v-checkbox
          v-model="child.included"
          :label="[child.first_name, child.last_name].join(' ')"
          class="mt-0"
          hide-details
        />
      </v-col>
    </v-row>
    <div v-else>
      <v-row class="fs-16">
        <LabeledTextfield
          v-model="child.first_name"
          @change="update()"
          :aria-label="t('Enter a first name')"
          :autofocus="autofocus"
          :dense="dense"
          :hard-lock="hardLock"
          :readonly="readonly"
          :schema-id="child.schema_id"
          cols="12"
          field="first_name"
          message="First name"
          sm="4"
          mandatory
        />
        <LabeledTextfield
          v-model="child.middle_name"
          @change="update()"
          :aria-label="t('Enter an optional middle name')"
          :dense="dense"
          :hard-lock="hardLock"
          :placeholder="t('Optional')"
          :readonly="readonly"
          :schema-id="child.schema_id"
          cols="12"
          field="middle_name"
          message="Middle name"
          sm="4"
        />
        <LabeledTextfield
          v-model="child.last_name"
          @change="update()"
          :aria-label="t('Enter a last name')"
          :dense="dense"
          :hard-lock="hardLock"
          :readonly="readonly"
          :schema-id="child.schema_id"
          cols="12"
          field="last_name"
          message="Last name"
          sm="4"
          mandatory
        />
      </v-row>
      <v-row class="fs-16">
        <LabeledDateOfBirth
          v-model="child.dob"
          @change="update()"
          :aria-label="t('Enter a date of birth')"
          :dense="dense"
          :hard-lock="hardLock"
          :readonly="readonly"
          :schema-id="child.schema_id"
          cols="12"
          field="dob"
          message="Date of birth"
          mandatory
        />
      </v-row>
      <v-row
        v-if="!removeDisabled"
        dense
      >
        <v-col
          class="d-flex align-center"
          cols="12"
        >
          <v-spacer />
          <v-btn
            @click="remove"
            size="small"
            variant="text"
          >
            {{ t('Remove') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LabeledDateOfBirth from '@/shared/components/form/LabeledDateOfBirth.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['change', 'removal']);
const props = defineProps({
  autofocus: {
    type: Boolean,
    default: false,
  },
  dense: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
    default: null,
  },
  initialChild: {
    type: Object,
    required: true,
  },
  hardLock: {
    type: Boolean,
    default: false,
  },
  preventUpdate: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  removeDisabled: {
    type: Boolean,
    default: false,
  },
  includedCheck: {
    default: false,
    type: Boolean,
  },
  includedText: {
    default: 'Included',
    type: String,
  },
  staged: {
    default: false,
    type: Boolean,
  },
});

const eventBus = useEventBus();
const { t } = useI18n();

const child = ref(props.initialChild);

function remove() {
  if (props.staged) return;
  Api.child.destroy(child.value.id, () => {
    emit('removal', child.value);
  });
}

function update() {
  if (!props.staged) {
    if (props.preventUpdate) {
      emit('change', child.value);
    } else {
      Api.child.update(
        child.value.id,
        child.value,
        () => {
          emit('change');
        },
        (error) => {
          eventBus.chime(error.response.data.errors[0]);
        },
      );
    }
  }
}

watch(
  () => child.value?.dob,
  () => {
    update();
  },
);
</script>
