<template>
  <v-col
    v-if="enabled"
    :cols="cols"
    :lg="lg"
    :md="md"
  >
    <v-row dense>
      <v-col
        class="labeled-input mb-2"
        cols="12"
      >
        {{ $t(label) }}
      </v-col>
    </v-row>
    <v-row
      class="d-flex align-center"
      dense
    >
      <v-col
        cols="12"
        lg="3"
      >
        <v-text-field
          @click="toggleOpenDateDialog"
          :aria-label="$t(label)"
          :density="dense ? 'compact' : undefined"
          :disabled="$attrs.disabled || locked"
          :label="$t('Day')"
          :model-value="localeDateString"
          :placeholder="$attrs.disabled ? $t(disabledMessage) : ''"
          variant="filled"
          hide-details
          readonly
          tile
        />

        <v-dialog
          v-model="openDateDialog"
          width="320px"
        >
          <v-date-picker
            v-model="dateObject"
            @update:model-value="setDate"
            :allowed-dates="allowedDates"
            scrollable
          />
        </v-dialog>
      </v-col>
      <v-col
        cols="3"
        lg="2"
      >
        <v-text-field
          v-model="hours"
          :label="$t('Hour')"
          :rules="[validHours]"
          placeholder="0"
          suffix=":"
          type="number"
          variant="filled"
          hide-details
        />
      </v-col>
      <v-col
        cols="3"
        lg="2"
      >
        <v-text-field
          v-model="minutes"
          :label="$t('Minute')"
          :rules="[validMinutes]"
          placeholder="00"
          type="number"
          variant="filled"
          hide-details
        />
      </v-col>
      <v-col
        cols="4"
        lg="3"
      >
        <v-select
          v-model="zone"
          :items="['-08:00', '-07:00', '-06:00', '-05:00', '-04:00']"
          :label="$t('Timezone')"
          variant="filled"
          hide-details
        />
      </v-col>
      <v-col
        cols="2"
        lg="2"
      >
        <v-btn
          @click="clear"
          color="red"
          data-testId="close-button"
          variant="text"
          icon
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script setup>
import api from '@/shared/services/all_bright_finder';
import propsToRefs from '@/shared/utils/propsToRefs';
import useEventBus from '@/shared/composables/useEventBus';
import useSchematizedField from '@/shared/composables/useSchematizedField';
import { useI18n } from 'vue-i18n';
import { format, parse } from 'date-fns';
import { format as formatTz, formatInTimeZone } from 'date-fns-tz';
import { toIanaTimeZone } from '@/shared/services/date-helper';
import { onMounted } from 'vue';

const props = defineProps({
  autofocus: {
    type: Boolean,
    default: false,
  },
  bold: {
    type: Boolean,
    default: false,
  },
  cols: {
    type: String,
    default: '12',
  },
  dense: {
    type: Boolean,
    default: false,
  },
  description: {
    type: String,
    default: null,
  },
  disabledMessage: {
    type: String,
    default: null,
  },
  disallowBeforeDate: {
    type: String,
    default: null,
  },
  global: {
    default: false,
    type: Boolean,
  },
  lg: {
    type: String,
    default: null,
  },
  locked: {
    type: Boolean,
    default: false,
  },
  localeDate: {
    type: Boolean,
    default: false,
  },
  md: {
    type: String,
    default: null,
  },
  modelValue: {
    type: String,
    default: null,
  },
  outlined: {
    default: false,
    type: Boolean,
  },
  sm: {
    type: String,
    default: null,
  },
  veryDense: {
    type: Boolean,
    default: false,
  },
  ...useSchematizedField.props,
});

onMounted(loadOrganziations);

const date = ref(null);
const emit = defineEmits(['blur', 'change', 'input', 'update:modelValue']);
const eventBus = useEventBus();
const hours = ref(null);
const localValue = ref(props.modelValue);
const minutes = ref(null);
const openDateDialog = ref(false);
const orgTimeZone = ref(null);
const zone = ref(null);
const { locale } = useI18n();
const schematizedFieldParamKeys = ['field', 'hardLock', 'mandatory', 'message', 'schemaId'];
const { enabled, label, locked } = useSchematizedField(
  ...propsToRefs(props, schematizedFieldParamKeys),
);

const dateObject = computed(() =>
  date.value ? parse(date.value, 'yyyy-MM-dd', new Date()) : new Date(),
);

const localeDateString = computed(() => {
  if (!date.value) return null;

  if (!props.localeDate) {
    return formatInTimeZone(
      new Date(`${date.value} ${hours.value}:${minutes.value}:00 ${zone.value}`),
      orgTimeZone.value,
      'yyyy-MM-dd',
    );
  }

  return new Date(date.value).toLocaleDateString(locale.value, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'utc',
  });
});

const watchItems = computed(() => [localValue.value, orgTimeZone.value]);

watchEffect(() => {
  const [value, timezone] = watchItems.value;

  if (value && timezone) {
    let formattedValue = formatInTimeZone(
      new Date(value),
      timezone,
      "yyyy-MM-dd'T'HH:mm:ss.SSSXXX",
    );

    const dateStr = formattedValue.substring(0, 10);
    const time = formattedValue.split('.')[0].split('T')[1];
    const zoneStr = formattedValue.substring(23, formattedValue.length);
    const [hoursStr, minutesStr] = time?.split(':') ?? [null, null];

    date.value ||= dateStr;
    hours.value ||= hoursStr;
    minutes.value ||= minutesStr;
    zone.value ||= zoneStr;
  } else {
    date.value = null;
    hours.value = null;
    minutes.value = null;
    zone.value = null;
  }
});

watchEffect(() => {
  date.value;
  hours.value;
  minutes.value;
  zone.value;

  processUpdate();
});

function allowedDates(date) {
  if (props.disallowBeforeDate) {
    if (new Date(date) >= new Date(props.disallowBeforeDate)) {
      return true;
    }
    return false;
  }
  return true;
}

function clear() {
  date.value = null;
  hours.value = null;
  minutes.value = null;
  zone.value = null;
  handleUpdateModelValue(null);
  handleBlur(null);
}

function handleBlur(event) {
  emit('blur', event);
  if (props.global) {
    eventBus.blur(event);
  }
}

function handleUpdateModelValue(event) {
  localValue.value = event;
  emit('update:modelValue', event);
  emit('change', event);
  emit('input', event);
}

function processUpdate() {
  if (date.value) {
    if (!hours.value) {
      hours.value = format(new Date(), 'HH');
    }

    if (!minutes.value) {
      minutes.value = format(new Date(), 'mm');
    }

    if (!zone.value) {
      zone.value = formatTz(date.value, 'XXX', {
        timeZone: orgTimeZone.value,
      });
    }
  }

  if (date.value instanceof Date) {
    date.value = format(date.value, 'yyyy-MM-dd');
  }

  if (date.value && hours.value && minutes.value && zone.value) {
    if (validHours(hours.value) && validMinutes(minutes.value)) {
      const newVal = [
        date.value,
        'T',
        hours.value.padStart(2, '0'),
        ':',
        minutes.value.padStart(2, '0'),
        ':00.000',
        zone.value,
      ].join('');
      if (newVal !== localValue.value) {
        handleUpdateModelValue(newVal);
        handleBlur(newVal);
      }
    } else {
      eventBus.error('Invalid time');
    }
  }
}

function setDate(value) {
  toggleOpenDateDialog();
  date.value = value;
}

function toggleOpenDateDialog() {
  openDateDialog.value = !openDateDialog.value;
}

function validHours(value) {
  if (!value) return true;

  const number = parseInt(value, 10);
  return number >= 0 && number <= 23;
}

function validMinutes(value) {
  if (!value) return true;

  const number = parseInt(value, 10);
  return number >= 0 && number <= 59;
}

async function loadOrganziations() {
  const { data: organizationData } = await api.admin.organization.get();
  orgTimeZone.value = toIanaTimeZone(organizationData.time_zone) ?? 'America/Los_Angeles';
}
</script>
