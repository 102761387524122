<template>
  <v-container
    v-if="$i18n.locale && webpage"
    :class="containerClass"
    class="my-8"
  >
    <grid-layout
      v-model:layout="webpage.layout[size]"
      :auto-size="true"
      :col-num="36"
      :is-draggable="false"
      :is-resizable="false"
      :row-height="8"
    >
      <grid-item
        v-for="item in webpage.layout[size]"
        :key="item.i"
        :h="item.h"
        :i="item.i"
        :w="item.w"
        :x="item.x"
        :y="item.y"
      >
        <PageElement
          v-model="elements[item.i]"
          mode="view"
        />
      </grid-item>
    </grid-layout>
  </v-container>
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
import PageElement from '@/shared/components/PageElement.vue';
import { GridLayout, GridItem } from 'grid-layout-plus';
import { useDisplay } from 'vuetify';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();
const route = useRoute();
const display = useDisplay();
const store = useStore();
const { t } = useI18n();

let elements = ref(null);
let lang = ref(null);
let webpage = ref(null);

let containerClass = computed(() => {
  if (size.value === 'lg') return 'mxw-1200';
  if (size.value === 'md') return 'mxw-800';
  return 'mxw-440';
});

let size = computed(() => {
  if (display.lgAndUp) return 'lg';
  if (display.mdAndUp) return 'md';
  return 'sm';
});

watch(route.params.webpageId, () => identifyUser(), { immediate: true });

function load() {
  Api.public_api.organization.webpage.get(route.params.webpageId, (resp) => {
    webpage.value = resp.data;
    lang.value = route.query.lang || locale || store.state.profile?.default_locale;
    const title = t([store.state.config.title, webpage.value.name].join(' - '));
    document.title = title;
    const els = {};
    webpage.value.elements.forEach((el) => (els[el.i] = el));
    elements.value = els;
  });
}

function identifyUser() {
  store.dispatch('identify', {
    success() {
      load();
    },
    failure() {
      load();
    },
  });
}
</script>

<style scoped>
.v-btn__content {
  white-space: normal !important;
}
</style>
