<template>
  <v-dialog
    v-if="provider"
    v-model="visible"
    data-testId="book-tour-dialog"
    fullscreen
  >
    <div class="h-100pc w-100pc bg-super-light-blue px-4 oy-scroll">
      <div class="mxw-800 mx-auto p-relative">
        <div class="ta-right">
          <v-btn
            @click="visible = false"
            :aria-label="t('Close')"
            variant="text"
            icon
          >
            <v-icon
              icon="close"
              size="32"
            />
          </v-btn>
        </div>

        <div class="w-100pc bg-white pa-6 rounded elevation-1">
          <v-card-title
            v-if="step < 5"
            class="d-flex justify-center wrap-container"
          >
            <div class="wrap-center">
              <span class="text-ellipsis">
                {{ t('Request a tour with') }} {{ provider.name }} ({{ provider.tour_time }}
                {{ t('minutes') }})
              </span>
            </div>
          </v-card-title>

          <v-card-subtitle
            v-if="step < 5"
            class="c-light-black d-flex justify-center fs-16 py-3 flex-grow-1 text-ellipsis"
          >
            {{ t('Please fill out the form fields below and someone will be in touch!') }}
          </v-card-subtitle>

          <v-card-text class="py-0">
            <div
              v-if="loading"
              class="pa-16 ta-center"
            >
              <v-progress-circular
                size="40"
                indeterminate
              />
            </div>

            <v-stepper
              v-if="step < 5"
              v-model="step"
              :items="['Request', 'Children', 'Hours', 'Message']"
              flat
              hide-actions
            >
              <!-- Request -->
              <template #item.1>
                <p class="c-black fs-18 fw-600">
                  {{ t('Preferred tour time:') }}
                </p>
                <div class="d-flex justify-center align-center mb-2">
                  <v-btn
                    @click="page -= 1"
                    :aria-label="t('Go back 1 day')"
                    :disabled="page < 2"
                    variant="text"
                    icon
                  >
                    <v-icon>west</v-icon>
                  </v-btn>
                  <div
                    v-if="tour_openings.length > 0"
                    class="mx-4"
                  >
                    <span class="me-2">
                      {{ t('Week of') }}
                    </span>
                    <span v-text="new Date(tour_openings[0].day).toLocaleDateString()" />
                  </div>
                  <v-btn
                    @click="page += 1"
                    :aria-label="t('Go forward 1 day')"
                    variant="text"
                    icon
                  >
                    <v-icon>east</v-icon>
                  </v-btn>
                </div>
                <div :class="dayClass">
                  <TourCalendarDay
                    v-for="day in tour_openings"
                    @select="selectTime(day.day, $event)"
                    :key="day.day"
                    :active="selectedTime[0] === day.day"
                    :day="day"
                  />
                </div>
              </template>

              <!-- Children -->
              <template #item.2>
                <div v-if="children.length > 0">
                  <div
                    v-if="existingChildren.length > 0"
                    class="mb-6"
                  >
                    <p class="fw-600">
                      {{ t('Which child are you requesting a tour for?') }}
                    </p>
                    <div
                      v-for="(child, index) in children.filter((child) => child.existing)"
                      :key="child.id"
                      class="mb-3"
                    >
                      <Child
                        :included-check="child.existing"
                        :index="index"
                        :initial-child="child"
                        remove-disabled
                      />
                    </div>
                  </div>

                  <p
                    v-if="existingChildren.length > 0"
                    class="fw-600"
                  >
                    {{ t('Or, add a new child') }}
                  </p>
                  <div
                    v-for="(child, index) in children.filter((child) => !child.existing)"
                    :key="child.id"
                    class="mb-3"
                  >
                    <Child
                      @removal="remove"
                      :included-check="child.existing"
                      :index="index"
                      :initial-child="child"
                      :remove-disabled="index === 0 && existingChildren.length === 0"
                    />
                  </div>
                  <div>
                    <a
                      @click="addChild"
                      class="fs-16"
                      small
                    >
                      {{ t('Add child') }}
                    </a>
                  </div>
                </div>
              </template>

              <!-- Hours -->
              <template #item.3>
                <div class="mb-6">
                  <p class="c-black fs-16 fw-600">
                    {{ t('What are your preferred hours of care?') }}
                  </p>
                  <v-textarea
                    v-model="hoursMessage"
                    :placeholder="
                      t(
                        'What days and hours do you require care? Do all children require the same hours of care?',
                      )
                    "
                    rows="3  "
                    variant="outlined"
                    hide-details
                  />
                </div>
                <div>
                  <p class="c-black fs-16 fw-600">
                    {{ t('What date do you need care? This can be an estimate.') }}
                  </p>
                  <v-date-picker
                    v-model="careStartDate"
                    :landscape="display.mdAndUp"
                    class="b-1 dense bc-primary"
                    full-width
                  />
                </div>
              </template>

              <!-- Message -->
              <template #item.4>
                <p class="c-black fs-16 fw-500">
                  {{ t("Is there anything you'd like to tell the provider?") }}
                </p>
                <v-textarea
                  v-model="otherMessage"
                  :placeholder="t('Optional')"
                  variant="outlined"
                  hide-details
                />
              </template>
            </v-stepper>

            <!-- Finishing step -->
            <div
              v-if="step == 5"
              class="pa-8 ta-center mxw-600 mx-auto"
            >
              <div class="mxw-300 mx-auto mb-4">
                <img
                  class="w-100pc"
                  src="https://res.cloudinary.com/bridgecare/image/upload/v1627582695/media/undraw_Mailbox_re_dvds_1_ego0ke.svg"
                />
              </div>

              <div class="fs-24 fw-600 mb-4">
                {{ t('Your request has been sent!') }}
              </div>

              <div class="fs-16 fw-500 mb-3">
                {{
                  t(
                    "Remember, your tour isn't confirmed until the provider accepts it. We will send you a confirmation when that happens.",
                  )
                }}
              </div>

              <div class="fs-16 fw-500 mb-4">
                {{ t('You can schedule a tour with more than one provider!') }}
              </div>

              <v-btn
                @click="visible = false"
                color="primary"
                size="x-large"
              >
                {{ t('Okay, got it') }}
              </v-btn>
            </div>
          </v-card-text>

          <v-card-actions v-if="step < 5">
            <v-btn
              v-if="step == 1"
              @click="visible = false"
              :ripple="false"
              size="x-large"
              variant="text"
            >
              {{ t('Cancel') }}
            </v-btn>

            <v-btn
              v-if="step > 1"
              @click="back"
              :ripple="false"
              size="x-large"
              variant="text"
            >
              {{ t('Back') }}
            </v-btn>

            <v-spacer />

            <v-btn
              v-if="step < 4"
              @click="handleNextClick"
              :ripple="false"
              color="primary"
              size="x-large"
            >
              {{ t('Next') }}
            </v-btn>

            <v-btn
              v-if="step == 4"
              @click="send"
              :disabled="processing"
              :ripple="false"
              color="primary"
              size="x-large"
            >
              {{ t('Request a tour') }}
            </v-btn>
          </v-card-actions>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import api from '@/shared/services/all_bright_finder';
import Child from '@/parent/components/children/Child.vue';
import TourCalendarDay from '@/shared/components/TourCalendarDay.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useDisplay } from 'vuetify';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['booked']);
const props = defineProps({
  provider: {
    type: Object,
    default: null,
  },
});

const display = useDisplay();
const eventBus = useEventBus();
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const store = useStore();

const careStartDate = ref(null);
const children = ref([]);
const defaultChild = ref({});
const hoursMessage = ref(null);
const loading = ref(true);
const otherMessage = ref(null);
const page = ref(1);
const processing = ref(false);
const selectedTime = ref([]);
const step = ref(1);
const text = ref(null);
const tourReservations = ref([]);
const tour_openings = ref([]);
const visible = ref(false);

const dayClass = computed(() => {
  if (display.smAndDown.value) return '';
  return 'd-flex align-start justify-space-between px-2';
});

const existingChildren = computed(() => children.value.filter((child) => child.existing));

const nextDisabled = computed(() => {
  if (step.value === 1) {
    return [!selectedTime.value[0], 'Select a date and time to continue'];
  }

  if (step.value === 2) {
    if (selectedChildren.value.length === 0) return [true, 'Select a child to continue'];
    if (selectedChildren.value.some((child) => !child.first_name || !child.dob))
      return [true, 'Fill out all child information to continue'];
  }

  if (step.value === 3) {
    if (!hoursMessage.value) return [true, 'Let us know what hours you will need care to continue'];
  }

  return [false];
});

const selectedChildren = computed(() =>
  children.value
    .filter((child) => !child.existing)
    .concat(children.value.filter((child) => child.existing && child.included)),
);

async function addChild() {
  api.child.create(defaultChild.value, (response) => {
    children.value.push(response.data);
  });
}

function back() {
  step.value -= 1;
}

async function handleNextClick() {
  const [disabled, message] = nextDisabled.value;

  if (disabled) {
    eventBus.chime(message);
    return;
  }

  next();
}

async function loadChildren() {
  api.child.index((response) => {
    children.value = response.data;

    children.value = children.value.map((child) => {
      if (child.first_name && child.last_name && child.dob) {
        return {
          ...child,
          existing: true,
        };
      }

      return child;
    });

    if (children.value.length === 0) {
      return addChild();
    }
  });
}

async function loadTourOpenings() {
  api.public_api.provider.tour_opening.index(
    { page: page.value },
    props.provider.id,
    (response) => {
      tour_openings.value = response.data;
      processing.value = false;
      loading.value = false;
    },
  );
}

async function loadTourReservations() {
  api.tour_reservation.index({}, (response) => {
    tourReservations.value = response.data;
    const existingTour = tourReservations.value.find(
      (tourReservation) => tourReservation.provider_id === props.provider.value.id,
    );
    emit('booked', existingTour);
  });
}

function next() {
  step.value += 1;
}

function open() {
  step.value = 1;
  careStartDate.value = null;
  processing.value = null;
  otherMessage.value = null;
  hoursMessage.value = null;
  selectedTime.value = [];
  children.value = [];

  if (!store.state.profile || store.state.profile.is_anonymous === true) {
    if (route.params.searchId) {
      router.push({
        name: 'Signup',
        query: {
          searchId: route.params.searchId,
          providerId: props.provider.id,
          action: 'tour',
        },
      });
    } else {
      router.push({
        name: 'Signup',
        query: {
          providerId: props.provider.id,
          action: 'tour',
        },
      });
    }
  } else {
    text.value = null;
    visible.value = true;
    loading.value = true;
    if (store.state.profile) {
      void loadTourOpenings();
      void loadChildren();
    }
  }
}

function remove(removeChild) {
  children.value.splice(
    children.value.findIndex((child) => child.id === removeChild.id),
    1,
  );
}

function send() {
  processing.value = true;
  api.tour_reservation.create(
    {
      care_start_date: careStartDate.value,
      children: selectedChildren.value.map((child) => child.id),
      hours_message: hoursMessage.value,
      other_message: otherMessage.value,
      provider_id: props.provider.id,
      start_time: selectedTime.value[1],
    },
    (response) => {
      step.value = 5;
      emit('booked', response.data);
    },
    (err) => {
      eventBus.chime(err.response.data.errors.join('. '));
      processing.value = false;
    },
  );
}

function selectTime(date, time) {
  selectedTime.value = [date, time];
}

watch(page, () => {
  void loadTourOpenings();
});

onMounted(() => {
  if (store.state.profile) {
    void loadTourReservations();
  }
});

defineExpose({ open });
</script>

<style scoped>
.wrap-container {
  overflow: hidden;
}

.wrap-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

@media (max-width: 600px) {
  .text-ellipsis {
    white-space: normal;
  }
}
</style>
