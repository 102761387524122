<template>
  <v-card
    class="mb-2 bb-1 bt-1 bc-extra-light-grey"
    elevation="0"
  >
    <v-row>
      <v-col>
        <div
          @click="findingVisible = !findingVisible"
          class="c-primary bc-body fw-600 pointer"
        >
          <span
            v-text="finding.title"
            class="me-2"
          />
          <v-icon
            class="c-primary"
            icon="arrow_drop_down"
          />
        </div>
      </v-col>
    </v-row>

    <v-row v-if="findingVisible">
      <v-col>
        <div class="mb-1 bc-caption">
          <span class="fw-600">{{ $t('Regulation ID') }}:</span>
          <span v-text="finding.regulation_number" />
        </div>
        <div class="mb-1 bc-caption">
          <span class="fw-600">{{ $t('Date') }}:</span>
          <span v-text="finding.date" />
        </div>
        <div class="mb-3 bc-caption">
          <span class="fw-600">{{ $t('Status') }}:</span>
          <span v-text="finding.status" />
        </div>
        <div class="bc-body fw-600 mb-1">
          {{ $t('Non-compliance area') }}
        </div>
        <div
          v-text="finding.regulation_description"
          class="bc-body c-light-black mb-3"
        />
        <div class="bc-body fw-600 mb-1">
          {{ $t('Correction required') }}
        </div>
        <div
          v-text="finding.description"
          class="bc-body c-light-black mb-3"
        />
        <div class="bc-body fw-600 mb-1">
          {{ $t('Correction response') }}
        </div>
        <div
          v-text="finding.response"
          class="bc-body c-light-black"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
defineProps({
  finding: {
    type: Object,
    default: null,
  },
});

const findingVisible = ref(false);
</script>
