<template>
  <v-dialog
    v-if="provider"
    v-model="visible"
    max-width="530"
  >
    <v-card>
      <v-card-title>{{ $t('Message') }} {{ provider.name }}</v-card-title>
      <v-card-text>
        <p class="c-black fs-16 fw-500">
          {{ $t(MESSAGE_INSTRUCTION) }}
        </p>
        <v-textarea
          v-model="text"
          :placeholder="$t('Your message')"
          data-cy="send_provider_message"
          variant="outlined"
          hide-details
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="visible = false"
          size="x-large"
          variant="text"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          @click="send()"
          :disabled="!text || processing"
          color="primary"
          data-cy="send_button"
          size="x-large"
        >
          {{ $t('Send') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
import useEventBus from '../composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const MESSAGE_INSTRUCTION =
  'Once you click send, we’ll connect you with the provider over email so that you can learn more about applying, tuition, or ask other questions.';

defineExpose({ open });

const props = defineProps({
  provider: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['messaged']);
const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const store = useStore();

const text = ref(null);
const visible = ref(false);
const processing = ref(false);

onMounted(() => {
  if (store.state.profile) loadMessages();
});

function loadMessages() {
  Api.message.index({ provider_id: props.provider.id }, (resp) => {
    emit(
      'messaged',
      resp.data.find((message) => message.provider_id === props.provider.id),
    );
  });
}

function open() {
  if (store.state.profile.is_anonymous === true) {
    if (route.params.searchId) {
      router.push({
        name: 'Signup',
        query: {
          searchId: route.params.searchId,
          providerId: props.provider.id,
          action: 'message',
        },
      });
    } else {
      router.push({
        name: 'Signup',
        query: { providerId: props.provider.id, action: 'message' },
      });
    }
  } else {
    text.value = null;
    visible.value = true;
    processing.value = false;
  }
}

function send() {
  processing.value = true;
  Api.message.create(
    {
      inquiry: true,
      text: text.value,
      provider_id: props.provider.id,
      member_id: store.state.profile.id,
    },
    (resp) => {
      emit('messaged', resp.data);
      visible.value = false;
      eventBus.chime('Your message has been sent!');
    },
    (err) => {
      eventBus.chime(err.response.data.errors.join('. '));
      processing.value = false;
    },
  );
}
</script>
