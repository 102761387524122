<template>
  <span
    :aria-label="t('required')"
    :class="fontSize"
    class="c-red"
  >
    *
  </span>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  size: {
    type: String,
    default: '16',
  },
});

const { t } = useI18n();

const fontSize = computed(() => {
  return props.size === 'inherit' ? 'fs-inherit' : `fs-${props.size}`;
});
</script>
