<template>
  <v-dialog
    v-model="visible"
    fullscreen
    persistent
  >
    <v-card class="bg-super-light-blue">
      <v-container class="mxw-1200">
        <v-card
          v-if="loaded"
          data-testid="agreement-dialog"
          border
          flat
          tile
        >
          <v-card-title>
            <v-row class="d-flex align-center">
              <v-col
                class="d-flex align-center"
                cols="8"
              >
                <span>
                  {{ $t(agreement.name) }}
                </span>
              </v-col>

              <v-col class="d-flex justify-end">
                <v-btn
                  @click="close"
                  color="primary"
                  variant="outlined"
                >
                  <v-icon class="me-2"> close </v-icon>
                  {{ $t('Close') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-divider class="mb-6" />

          <template v-if="loaded">
            <v-card-text>
              <QuestionSet
                v-model="agreement"
                :questions="validQuestions"
                :readonly="readonly"
                :schema="schema.definition"
                expanded
                flat
                hide-actions
                outlined-sections
                paddingless
                very-dense
              />

              <div
                v-for="question in verificationQuestions"
                :key="question.id"
                class="mb-6"
              >
                <FormQuestion
                  :color="null"
                  :elevation="0"
                  :subtitle="question.verification_subtitle"
                  :title="question.verification_title"
                  dense
                  hide-actions
                  outlined
                  tile
                  very-dense
                >
                  <template v-if="!readonly">
                    <AttachmentUploader
                      @uploaded="attachments.push($event)"
                      :ref="['uploader', question.id].join('')"
                      :owner="{
                        business_id: $store.state.profile.business_id,
                        type: 'Agreement',
                        id: agreement.id,
                        tag: question.id,
                        tags: [agreement.id, question.id],
                      }"
                      class="mb-4"
                    />
                  </template>
                  <AttachmentList
                    @change="loadAttachments()"
                    @delete="loadAttachments"
                    :attachments="
                      attachments.filter((attachment) => attachment.tags.includes(question.id))
                    "
                    class="mb-6"
                  />
                </FormQuestion>
              </div>
            </v-card-text>
          </template>

          <template v-else>
            <v-progress-linear indeterminate />
          </template>

          <v-divider class="mb-6" />

          <div class="px-4 pb-6">
            <v-row>
              <v-col>
                <v-btn
                  @click="close"
                  color="primary"
                  size="x-large"
                  variant="outlined"
                >
                  <v-icon class="me-2"> close </v-icon>
                  {{ $t('Close') }}
                </v-btn>
              </v-col>
              <v-col
                v-if="agreement && !agreement.submitted_at && !readonly"
                class="d-flex justify-end"
              >
                <v-btn
                  @click="saveDraft"
                  :loading="processing"
                  class="me-3"
                  color="primary"
                  size="x-large"
                  variant="outlined"
                >
                  {{ $t('Save draft') }}
                </v-btn>
                <v-btn
                  @click="submit"
                  :disabled="!completed"
                  :loading="processing"
                  color="primary"
                  data-testid="submit-button"
                  size="x-large"
                >
                  {{ $t('Finish and submit') }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import LinkedList from '@/shared/services/linked-list';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

const props = defineProps({
  role: {
    type: String,
    default: 'member',
  },
});

const emit = defineEmits(['change']);

const agreement = ref(null);
const attachments = ref([]);
const eventBus = useEventBus();
const loaded = ref(false);
const processing = ref(false);
const schema = ref(null);
const visible = ref(false);
const questions = ref([]);
const router = useRouter();
const route = useRoute();
const store = useStore();

const incompleteQuestions = computed(() => {
  return mandatoryQuestions.value.filter((question) =>
    question.synced_attributes.some((sa) => {
      const attrParts = sa.name.split('.');
      return !agreement.value.custom[attrParts[attrParts.length - 1]];
    }),
  );
});

const incompleteVerificationQuestions = computed(() => {
  return mandatoryQuestions.value
    .filter((question) => question.verification)
    .filter(
      (question) =>
        attachments.value.filter((attachment) => attachment.tags.includes(question.id)).length ===
        0,
    );
});

const completed = computed(() => {
  return (
    incompleteQuestions.value.length === 0 && incompleteVerificationQuestions.value.length === 0
  );
});

const mandatoryQuestions = computed(() => {
  return validQuestions.value.filter((question) => question.mandatory);
});

const readonly = computed(() => {
  return !!agreement.value.submitted_at || agreement.value.member_id !== store.state.profile.id;
});

const verificationQuestions = computed(() => {
  return LinkedList.sort(questions.value)
    .filter((question) => question.verification)
    .filter((question) => question.published && question.valid);
});

const validQuestions = computed(() => {
  return LinkedList.sort(questions.value).filter((question) => question.valid);
});

watch(
  () => route.query.agreementId,
  async (newVal) => {
    if (newVal) {
      await open();
    } else {
      close();
    }
  },
  { immediate: true },
);

function close() {
  if (route.query.agreementId) {
    router.push({ query: {} });
  }
  visible.value = false;
  resetAgreement();
}

async function open() {
  resetAgreement();
  visible.value = true;
  const resp = await Api[props.role].agreement.get(route.query.agreementId);
  agreement.value = resp.data;
  schema.value = store.state.schemas[agreement.value.schema_id];
  await loadAttachments();
  await loadQuestions();
  loaded.value = true;
}

function resetAgreement() {
  agreement.value = null;
  loaded.value = false;
  schema.value = null;
  questions.value = [];
}

function saveDraft() {
  processing.value = true;
  agreement.value.submit_requested = false;
  Api[props.role].agreement.update(agreement.value.id, agreement.value, (resp) => {
    agreement.value = resp.data;
    processing.value = false;
    eventBus.chime('Saved');
  });
}

function submit() {
  processing.value = true;
  agreement.value.submit_requested = true;
  Api[props.role].agreement.update(agreement.value.id, agreement.value, () => {
    processing.value = false;
    eventBus.chime('Submitted successfully');
    emit('change');
    close();
  });
}

async function validate() {
  const assertions = questions.value.map((question) => {
    if (question.conditions.length > 0) {
      return {
        assertion: {
          conditions: question.conditions,
          value: this[question.synced_model.toLowerCase()],
        },
      };
    }
    return { assertion: { conditions: [], value: {} } };
  });

  const { data } = await Api.public_api.assertion.promiseBulkCreate(assertions);
  const { results } = data;

  questions.value.forEach((question, index) => {
    // eslint-disable-next-line no-param-reassign
    question.valid = results[index].result;
  });
}

async function loadAttachments() {
  const params = { owner_id: agreement.value.id, owner_type: 'Agreement' };
  const role = props.role === 'parent' ? 'member' : props.role;

  const resp = await Api[role].attachment.index(params);
  if (resp?.status !== 200) return;

  attachments.value = resp.data;
}

async function loadQuestions() {
  await Api.public_api.organization.question.index(
    { owner_id: schema.value.id, owner_type: 'Schema' },
    async (resp) => {
      questions.value = resp.data;
      await validate();
    },
  );
}
</script>
