<template>
  <v-card
    :border="outlined"
    :class="cardClass"
    :color="color"
    :elevation="elevation"
    :tile="tile || landscape"
    class="mx-auto px-0 ma-0"
  >
    <v-card-text :class="cardTextClass">
      <v-row
        class="d-flex align-start"
        dense
      >
        <v-col
          :class="landscape ? 'py-2' : 'py-0'"
          :cols="cols"
        >
          <slot name="preamble" />

          <template v-if="header">
            <div
              :class="headerClass"
              class="fs-20"
              data-testid="question-header"
            >
              {{ t(header) }}
            </div>
          </template>

          <h1
            v-if="title"
            :aria-label="titleLabel"
            :class="titleClass"
            aria-level="1"
            class="focus-very-visible"
            data-cy="question-title"
            data-testid="question-title"
            role="heading"
            tabindex="0"
          >
            <MarkdownContent :content="renderText(title)" />

            <required-indicator
              v-if="!showIndicators && mandatory"
              size="inherit"
            />
          </h1>

          <h2
            v-if="subtitle"
            :aria-label="renderText(subtitle)"
            aria-level="2"
            class="focus-very-visible fs-16 fw-500 c-light-black"
            data-cy="question-subtitle"
            data-testid="question-subtitle"
            role="heading"
            tabindex="0"
          >
            <MarkdownContent :content="renderText(subtitle)" />
          </h2>

          <slot name="append-header" />
        </v-col>

        <v-col
          v-if="showPassing"
          class="ta-right py-0 pe-2"
          cols="4"
        >
          <QuestionIndicators :passing="passing" />
        </v-col>

        <v-col
          v-else-if="showIndicators"
          class="ta-right py-0 pe-2"
          cols="4"
        >
          <QuestionIndicators
            :conditional="conditional"
            :mandatory="mandatory"
            :published="published"
            :verification="verification"
          />
        </v-col>

        <v-col
          :class="slotContainerClass"
          :cols="landscape ? '6' : '12'"
        >
          <v-row
            class="d-flex align-center"
            dense
          >
            <v-col :class="slotClass">
              <slot />
            </v-col>

            <template v-if="displayNotes">
              <v-col class="flex-grow-0 flex-shrink-0 pe-1 ps-3 py-2 align-start">
                <v-icon
                  @click="$emit('click:notes')"
                  :class="notesCount == 0 ? 'dimmed' : 'c-primary'"
                  icon="sticky_note_2"
                  size="22"
                />
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>

      <slot name="append-content" />
    </v-card-text>

    <template v-if="!hideActions">
      <v-row
        :class="xsOnly ? 'flex-column-reverse' : ''"
        class="d-flex align-center mt-4 px-3"
        dense
      >
        <v-col>
          <template v-if="reversible">
            <v-btn
              @click="$emit('back')"
              :block="xsOnly"
              :loading="processing"
              :ripple="false"
              class="focus-very-visible"
              color="primary"
              data-cy="back-button"
              size="x-large"
              variant="outlined"
            >
              <span>
                {{ t('Back') }}
              </span>
            </v-btn>
          </template>
        </v-col>

        <v-col
          :class="display.smAndUp ? 'justify-end' : 'justify-start'"
          class="d-flex"
        >
          <template v-if="skippable">
            <v-btn
              @click="$emit('skip')"
              :block="xsOnly"
              :loading="processing"
              :ripple="false"
              class="focus-very-visible"
              color="primary"
              size="x-large"
              variant="outlined"
            >
              <span>
                {{ t('Skip for now') }}
              </span>
            </v-btn>
          </template>

          <template v-if="forwardable">
            <v-btn
              @click="$emit('next')"
              :block="xsOnly"
              :disabled="nextDisabled"
              :loading="processing"
              :ripple="false"
              class="focus-very-visible"
              color="primary"
              data-cy="next-button"
              data-testid="next-button"
              size="x-large"
            >
              <span>
                {{ t(nextText) }}
              </span>

              <template v-if="nextArrow">
                <v-icon
                  icon="east"
                  end
                />
              </template>
            </v-btn>
          </template>

          <template v-if="finishable">
            <v-btn
              @click="$emit('finish')"
              :block="xsOnly"
              :disabled="finishDisabled"
              :loading="processing"
              :ripple="false"
              class="focus-very-visible"
              color="primary"
              data-cy="finish-button"
              size="x-large"
            >
              <span>
                {{ t(finishText) }}
              </span>
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </template>

    <v-divider
      v-if="divided"
      class="mb-0"
    />
  </v-card>
</template>

<script setup>
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import QuestionIndicators from '@/shared/components/form/QuestionIndicators.vue';
import RequiredIndicator from '@/shared/components/RequiredIndicator.vue';
import { useDisplay } from 'vuetify';
import useRenderLiquid from '@/shared/composables/useRenderLiquid';
import { useI18n } from 'vue-i18n';

defineEmits(['back', 'click:notes', 'finish', 'next', 'skip']);

const props = defineProps({
  bold: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: 'transparent',
  },
  conditional: {
    type: Boolean,
    default: false,
  },
  dense: {
    type: Boolean,
    default: false,
  },
  displayNotes: {
    default: false,
    type: Boolean,
  },
  divided: {
    default: false,
    type: Boolean,
  },
  elevation: {
    type: Number,
    default: 0,
  },
  finishable: {
    type: Boolean,
    default: false,
  },
  finishDisabled: {
    default: false,
    type: Boolean,
  },
  finishText: {
    type: String,
    default: 'Confirm and submit',
  },
  forwardable: {
    type: Boolean,
    default: true,
  },
  header: {
    type: String,
    default: null,
  },
  liquidRenderingEnabled: {
    type: Boolean,
    default: true,
  },
  hideActions: {
    type: Boolean,
    default: false,
  },
  landscape: {
    default: false,
    type: Boolean,
  },
  mandatory: {
    default: false,
    type: Boolean,
  },
  modelValue: {
    type: Object,
    default: () => ({}),
  },
  nextDisabled: {
    type: Boolean,
    default: false,
  },
  nextArrow: {
    type: Boolean,
    default: false,
  },
  nextText: {
    type: String,
    default: 'Next',
  },
  notesCount: {
    type: Number,
    default: null,
  },
  outlined: {
    default: false,
    type: Boolean,
  },
  paddingless: {
    default: false,
    type: Boolean,
  },
  passing: {
    type: Boolean,
    default: null,
  },
  processing: {
    default: false,
    type: Boolean,
  },
  published: {
    type: Boolean,
    default: null,
  },
  reversible: {
    type: Boolean,
    default: true,
  },
  skippable: {
    type: Boolean,
    default: false,
  },
  showIndicators: {
    type: Boolean,
    default: false,
  },
  showPassing: {
    type: Boolean,
    default: false,
  },
  subtitle: {
    type: String,
    default: null,
  },
  tile: {
    default: false,
    type: Boolean,
  },
  title: {
    type: String,
    default: null,
  },
  verification: {
    type: Boolean,
    default: false,
  },
  veryDense: {
    default: false,
    type: Boolean,
  },
});

const display = useDisplay();
const { renderText } = useRenderLiquid({
  liquidRenderingEnabled: props.liquidRenderingEnabled,
  modelValue: props.modelValue,
});
const { t } = useI18n();

const cardClass = computed(() => {
  return props.landscape ? 'py-0 bb-0' : 'py-2';
});

const cardTextClass = computed(() => {
  const classes = [];

  if (props.landscape) classes.push('px-4');

  if (props.paddingless) {
    classes.push('px-0 py-0 mx-0');
  } else if (display.smAndDown) {
    classes.push('px-0');
  } else {
    classes.push('px-2 py-2');
  }

  if (props.bold) classes.push('bg-extra-light-grey');

  return classes.join(' ');
});

const cols = computed(() => {
  if (props.landscape) return '6';
  if (props.showIndicators || props.showPassing) return '8';

  return '12';
});

const headerClass = computed(() => {
  return props.veryDense ? 'fw-500 mb-1' : 'fw-600 mb-3';
});

const titleLabel = computed(() => {
  const header = props.header || '';
  const base = [t(header), renderText(props.title)].filter((string) => string);
  if (props.mandatory) base.push(t('required'));

  return base.join(' - ');
});

const slotClass = computed(() => {
  if (props.landscape) return 'mt-0 flex-grow-1 flex-shrink-0';
  if (props.dense || props.veryDense) return 'mt-4';

  return 'mt-5';
});

const slotContainerClass = computed(() => {
  return props.landscape ? 'd-flex align-enter py-2' : 'py-0';
});

const titleClass = computed(() => {
  const classes = [];

  if (props.dense) {
    classes.push('fs-22 fw-500 mb-1');
  } else if (props.veryDense) {
    classes.push('fs-16 fw-500 mb-1');
  } else if (display.smAndDown) {
    classes.push('fs-24 fw-600 lh-30 mb-3');
  } else {
    classes.push('fs-30 fw-600 lh-36 mb-3');
  }

  if (props.bold) classes.push('fw-600');

  return classes.join(' ');
});

const xsOnly = computed(() => display.xs && !display.smAndUp);
</script>
