<template>
  <v-dialog
    v-model="isVisible"
    :max-width="maxWidth"
    :persistent="persistent"
  >
    <v-card
      data-cy="resource-dialog"
      border
      flat
      tile
    >
      <v-card-title>
        <v-row class="d-flex align-center">
          <v-col>
            <template v-if="title">
              <span v-t="title" />
            </template>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn
              v-if="!hideDefaultClose"
              @click="close"
              :aria-label="t('Close')"
              class="focus-very-visible"
              icon="close"
              size="small"
              variant="flat"
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider class="mb-4" />

      <v-card-text>
        <slot
          :data="data"
          :unmounted="handleUnmount"
          name="content"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

defineProps({
  maxWidth: {
    type: Number,
    default: 400,
  },
  title: {
    type: String,
    default: null,
  },
  hideDefaultClose: Boolean,
  persistent: {
    type: Boolean,
    default: true,
  },
});

const { t } = useI18n();

const data = ref(null);
const isVisible = ref(false);

function close() {
  isVisible.value = false;
}

function handleUnmount() {
  data.value = null;
}

function open(newData) {
  data.value = newData;
  isVisible.value = true;
}

defineExpose({ close, open });
</script>
