<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card
    class="mb-4"
    border
    flat
    tile
  >
    <v-card-title class="fs-20 fw-800 c-black">
      {{ $t('Hours of operation') }}
    </v-card-title>
    <v-card-text>
      <v-row v-if="!provider.hours_description">
        <v-col>
          <div class="fs-16 fw-400 c-light-black">
            {{ $t('Hours not available') }}
          </div>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <HoursChunks :chunks="provider.hours_chunks" />
          <template v-if="provider.hours_other_description">
            <v-row class="mt-2">
              <v-col>
                <div class="bg-primary c-white pa-3">
                  {{ $t(provider.hours_other_description) }}
                </div>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
      <v-row v-if="provider.program_season">
        <v-col>
          <v-icon
            class="va-text-bottom me-2"
            color="secondary"
            icon="date_range"
            size="24"
          />
          <span class="c-black fs-16 fw-400">
            {{ $t(`${terms.program} offered`) }} {{ $t(provider.program_season) }}.
          </span>
        </v-col>
      </v-row>
      <div
        v-for="(field, index) in $store.state.schemas[provider.schema_id].meta.hours.append"
        :key="index"
      >
        <CustomField
          :field="field"
          :model-value="provider"
          :readonly="true"
          :schema-id="provider.schema_id"
          :show-title="true"
          class="fs-16"
          mode="show"
          show-icon="false"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup>
import CustomField from '@/shared/components/CustomField.vue';
import HoursChunks from '@/public/components/provider/HoursChunks.vue';
import useTerms from '@/shared/composables/useTerms';

const { terms } = useTerms();

defineProps({
  provider: {
    type: Object,
    required: true,
  },
});
</script>
