import axios from 'axios';
import { asyncApi, handleError } from '@/shared/services/api';

// Parent
import applicationSummary from '@/parent/services/api/application-summary';
import claim from '@/parent/services/api/claim';
import eligibilityScreener from '@/parent/services/api/eligibility-screener';
import enrollment from '@/parent/services/api/enrollment';
import familySubsidy from '@/parent/services/api/family-subsidy';
import form from '@/parent/services/api/form';
import householdMember from '@/parent/services/api/household-member';

// Shared
import child from '@/shared/services/api/child';
import cohortCourseCompletion from '@/shared/services/api/cohort-course-completion';
import cohortLesson from '@/shared/services/api/cohort-lesson';
import cohortMember from '@/shared/services/api/cohort-member';
import comment from '@/shared/services/api/comment';
import member from '@/shared/services/api/member';
import provider from '@/shared/services/api/provider';
import publicApi from '@/shared/services/api/public-api';
import subsidy from '@/shared/services/api/subsidy';
import screenerProgram from '@/shared/services/api/public/screener-program';
import translations from '@/shared/services/api/translations';

axios.defaults.withCredentials = true;
axios.defaults.headers.get.Accept = 'application/json';
axios.defaults.headers.patch.Accept = 'application/json';
axios.defaults.headers.post.Accept = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.get['X-Location'] = window.location.href;
axios.defaults.headers.get['X-Site'] = import.meta.env.VUE_APP_SITE_ID || window.location.host;
axios.defaults.headers.patch['X-Site'] = import.meta.env.VUE_APP_SITE_ID || window.location.host;
axios.defaults.headers.post['X-Site'] = import.meta.env.VUE_APP_SITE_ID || window.location.host;

export default {
  assignmentCompletion: asyncApi({
    endpoint: '/assignment_completions',
    payloadKey: 'assignment_completion',
  }),

  child,

  cohort: asyncApi({ endpoint: '/v2/cohorts' }),

  cohortCourseCompletion,

  cohortLesson,

  cohortMember,

  comment,

  contact: {
    base_url(email) {
      return `${window.api_url}/public/organizations/${window.organization_id}/contacts/${email}`;
    },

    get(email, success) {
      if (!success) return axios.get(this.base_url(email));
      return axios.get(this.base_url(email)).then(success);
    },

    update(email, contact, success) {
      if (!success) return axios.patch(this.base_url(email), { contact });
      return axios.patch(this.base_url(email), { contact }).then(success);
    },
  },

  enrollment,

  eligibilityScreener,

  favorite: {
    baseUrl() {
      return `${window.api_url}/favorites`;
    },

    create(favorite, success) {
      axios.post(this.baseUrl(), { favorite }).then(success);
    },

    destroy(id, success) {
      axios.delete(`${this.baseUrl()}/${id}`).then(success);
    },

    index(success) {
      axios.get(this.baseUrl()).then(success);
    },
  },

  grant: {
    attachment: {
      base_url(grantId, id) {
        let url = `${window.api_url}/grants/${grantId}/attachments`;
        if (id) url += `/${id}`;
        return url;
      },

      create(grantId, attachment, success, failure) {
        axios.post(this.base_url(grantId), { attachment }).then(success).catch(failure);
      },

      destroy(grantId, id, success) {
        axios.delete(this.base_url(grantId, id)).then(success);
      },

      get(grantId, id, success) {
        axios.get(this.base_url(grantId, id)).then(success);
      },

      index(grantId, success) {
        axios.get(this.base_url(grantId)).then(success);
      },

      update(grantId, id, attachment, success, failure) {
        axios.patch(this.base_url(grantId, id), { attachment }).then(success).catch(failure);
      },
    },
  },

  group: {
    baseUrl() {
      return `${window.api_url}/group`;
    },

    get(success) {
      if (!success) return axios.get(this.baseUrl());

      return axios.get(this.baseUrl()).then(success);
    },

    update(group, success, failure) {
      if (!success) return axios.patch(this.baseUrl(), { group });

      axios.patch(this.baseUrl(), { group }).then(success).catch(failure);
    },
  },

  downloadListsUrl(params) {
    const url = `${window.api_url}/download`;
    axios.get(url, { params, responseType: 'blob' }).then((resp) => {
      const windowUrl = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = windowUrl;
      link.setAttribute('download', `favorites.${params.format}`); // or any other extension
      document.body.appendChild(link);
      link.click();
    });
  },

  lesson_completion: {
    baseUrl(id) {
      let url = `${window.api_url}/lesson_completions`;
      if (id) url += `/${id}`;
      return url;
    },

    get(id, success) {
      if (success) {
        return axios.get(this.baseUrl(id)).then(success);
      }

      return axios.get(this.baseUrl(id));
    },

    index(filters, success) {
      if (success) {
        return axios.get(this.baseUrl(), { params: filters }).then(success);
      }

      return axios.get(this.baseUrl(), { params: filters });
    },
  },

  list: {
    baseUrl(listId) {
      if (listId) {
        return `${window.api_url}/lists/${listId}`;
      }
      return `${window.api_url}/lists`;
    },

    create(list, success) {
      axios.post(this.baseUrl(), { list }).then(success);
    },

    destroy(listId, success) {
      axios.delete(this.baseUrl(listId)).then(success);
    },

    get(listId, success) {
      axios.get(this.baseUrl(listId)).then(success);
    },

    index(success) {
      axios.get(this.baseUrl()).then(success);
    },

    promiseIndex() {
      return axios.get(this.baseUrl());
    },

    update(listId, list, success) {
      axios.patch(this.baseUrl(listId), { list }).then(success);
    },
  },

  message: {
    baseUrl() {
      return `${window.api_url}/messages`;
    },

    create(message, success, failure) {
      axios.post(this.baseUrl(), { message }).then(success).catch(failure);
    },

    get(id, success) {
      axios.get(`${this.baseUrl()}/${id}`).then(success);
    },

    index(filters, success) {
      axios.get(this.baseUrl(), { params: filters }).then(success);
    },
    promiseCreate(message) {
      return axios.post(this.baseUrl(), { message });
    },
  },

  match: {
    baseUrl(searchId) {
      return `${window.api_url}/searches/${searchId}/matches`;
    },

    index(searchId, params, success) {
      axios.get(this.baseUrl(searchId), { params }).then(success);
    },
  },

  member,

  parent: {
    agreement: {
      baseUrl() {
        return `${window.api_url}/parent/agreements`;
      },

      index(filters) {
        return axios.get(this.baseUrl(), { params: filters });
      },

      get(id, success) {
        if (success) {
          return axios.get(`${this.baseUrl()}/${id}`).then(success);
        }

        return axios.get(`${this.baseUrl()}/${id}`);
      },

      update(id, agreement, success) {
        if (success) {
          return axios.patch(`${this.baseUrl()}/${id}`, { agreement }).then(success);
        }

        return axios.patch(`${this.baseUrl()}/${id}`, { agreement });
      },
    },

    applicationSummary,

    attendance_event: {
      baseUrl(id) {
        let url = `${window.api_url}/parent/attendance_events`;
        if (id) url += `/${id}`;
        return url;
      },
      create(attendance_event, success, failure) {
        axios.post(this.baseUrl(), { attendance_event }).then(success).catch(failure);
      },
      get(id, success) {
        axios.get(this.baseUrl(id)).then(success);
      },
      index(filters, success) {
        axios.get(this.baseUrl(), { params: filters }).then(success);
      },

      update(id, attendance_event, success) {
        axios.patch(this.baseUrl(id), { attendance_event }).then(success);
      },
    },

    children: asyncApi({ endpoint: '/parent/children' }),

    claim,

    eligibilityScreener,

    enrollment,

    enrollment_status: {
      baseUrl(id) {
        return `${window.api_url}/parent/enrollment_status/${id}`;
      },

      update(id, enrollment, success, failure) {
        return axios.patch(this.baseUrl(id), { enrollment }).then(success).catch(failure);
      },
    },

    family_subsidy: familySubsidy,

    form,

    household_member: householdMember,

    subsidy: asyncApi({ endpoint: '/parent/subsidies' }),

    survey: {
      baseUrl() {
        return `${window.api_url}/parent/surveys`;
      },
      create(survey) {
        return axios.post(this.baseUrl(), { survey });
      },
    },
  },

  screenerProgram,

  program: {
    base_url(provider_id) {
      return `${window.api_url}/providers/${provider_id}/programs`;
    },

    create(provider_id, program, success, failure) {
      axios.post(this.base_url(provider_id), { program }).then(success).catch(failure);
    },

    destroy(provider_id, program_id, success, failure) {
      axios
        .delete(`${this.base_url(provider_id)}/${program_id}`)
        .then(success)
        .catch(failure);
    },

    index(provider_id, filters = {}) {
      return axios.get(this.base_url(provider_id), { params: filters }).catch(handleError);
    },

    update(provider_id, program_id, program, success, failure) {
      axios
        .patch(`${this.base_url(provider_id)}/${program_id}`, { program })
        .then(success)
        .catch(failure);
    },

    updateOrCreate(provider_id, program, success, failure) {
      if (program.id) {
        this.update(provider_id, program.id, program, success, failure);
      } else {
        this.create(provider_id, program, success, failure);
      }
    },
  },

  provider,

  public_api: publicApi,

  reservation: {
    base_url() {
      return `${window.api_url}/reservations`;
    },

    create(reservation, success, failure) {
      axios.post(this.base_url(), { reservation }).then(success).catch(failure);
    },
  },

  review: {
    base_url() {
      return `${window.api_url}/reviews`;
    },

    create(review, success) {
      axios.post(this.base_url(), { review }).then(success);
    },
  },

  search: {
    baseUrl(searchId) {
      if (searchId) {
        return `${window.api_url}/searches/${searchId}`;
      }
      return `${window.api_url}/searches`;
    },

    create(search, success, error) {
      axios.post(this.baseUrl(), { search }).then(success).catch(error);
    },

    get(id, success, failure) {
      axios.get(this.baseUrl(id)).then(success).catch(failure);
    },

    index(success, failure) {
      axios.get(this.baseUrl()).then(success).catch(failure);
    },

    update(id, search, success, failure) {
      axios.patch(this.baseUrl(id), { search }).then(success).catch(failure);
    },
  },

  subsidy,

  tour_reservation: {
    baseUrl(id) {
      let url = `${window.api_url}/tour_reservations`;
      if (id) url += `/${id}`;
      return url;
    },

    create(tour_reservation, success, failure) {
      axios.post(this.baseUrl(), { tour_reservation }).then(success).catch(failure);
    },

    index(filters, success) {
      axios.get(this.baseUrl(), { params: filters }).then(success);
    },
  },

  translations,
};
